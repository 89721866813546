import _is from "./is";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var is = _is;
exports = {
  arr: toArray,
  obj: toObject
};

function toArray(d) {
  return Array.prototype.slice.call(d, 0);
}

function toObject(d) {
  var by = "id",
      o = {};
  return arguments.length == 1 ? (by = d, reduce) : reduce.apply(this || _global, arguments);

  function reduce(p, v, i) {
    if (i === 0) p = {};
    p[is.fn(by) ? by(v, i) : v[by]] = v;
    return p;
  }
}

export default exports;
export const arr = exports.arr;